.locl-ll-sidebar-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 15px 0;

    &__collapse {
      width: 100%;
    }
  }

  &__submenu {
    transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
    padding: 10px 0;
    background-color: rgba(19, 181, 172, 0.45);

    a {
      padding-left: 50px;
      margin: 0;

      &:hover {
        background: transparent;
        
        .locl-ll-sidebar-content__submenu__content {
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    margin-top: auto;
  }

  &__collapse {
    :global {
      .ant-collapse-header {
        display: none !important;
      }
  
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
}
