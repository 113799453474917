.locl-ll-sidebar-category {
  width: 100%;

  &__element {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    padding: 11px 20px;
    margin: 10px 0;
    overflow: hidden;
    background: transparent;
    border: none;

    &:hover {
      background-color: rgba(19, 181, 172, 0.45);
    }
  }
 
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 10px;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 23px;
    color: #FFFFFF;
  }

  &__submenu {
    transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
    padding: 10px 0;
    background-color: rgba(19, 181, 172, 0.45);

    a {
      padding-left: 50px;
      margin: 0;

      &:hover {
        background: transparent;
      }

      &:hover div {
        font-weight: 600;
        color: #FFFFFF;
      }
    }
  }

  &__new {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    top: -3px;
    display: block;
    margin-left: 5px;
    background: #ff4861;
  }

  &__disabled {
    .locl-ll-sidebar-category {
      &__element {
        &:hover {
          background-color: transparent;
        }
      }

      &__title {
        color: #79B3B0;
      }

      &__submenu {
        a {       
          &:hover div {
            color: #79B3B0;
          }
        }
      }
    }
  }

  &__collaplse {
    :global {
      .ant-collapse-header {
        display: none !important;
      }
  
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
}
