.locl-range-picker {
  background: var(--white-color-500);
  border: 1px solid var(--secondary-color-300);
  box-shadow: 0 1px 2px rgba(31, 41, 55, .08);
  border-radius: 4px;
  width: 100%;
  height: 36px;
  padding: 8px;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-color-500);
  transition: all .2s;

  &:hover {
    border: 1px solid var(--gray-color-400);
    box-shadow: 0 0 0 3px #e5e7eb;
  }

  &:global(.ant-picker-focused) {
    border: 1px solid var(--primary-color-500) !important;
    box-shadow: 0 0 0 3px #e7f8f7 !important;
  }

  :global {
    .ant-picker-active-bar {
      background-color: var(--primary-color-500);
    }

    .ant-picker-range-separator + .ant-picker-input {
      pointer-events: none;
    }
  }
}

.locl-range-picker-popover {
  :global {
    .ant-picker-panel-container .ant-picker-presets ul li {
      border-radius: 2px;
      cursor: pointer;
      font-family: var(--main-font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 8px;
      margin-top: 0 !important;
    }

    .ant-picker-panel {
      &:first-of-type {
        border-right: 1px solid hsla(0, 0%, 95%, .498) !important;
      }

      .ant-picker-header { border-bottom: none; }
      
      .ant-picker-content {
        thead th {
          margin: 0;
          font-weight: 600;
          font-size: 13px;
          line-height: 24px;
          color: var(--secondary-color-500) !important;
        }

        tbody tr:last-of-type { display: none; }

        .ant-picker-cell {
          padding: 0;

          &:before { height: 100%; }
          &:after { display: none; }

          &.ant-picker-cell-range-hover,
          &.ant-picker-cell-in-range {
            &:before {
              background-color: transparent !important;
            }
            
            .ant-picker-cell-inner {
              border-radius: 0;
              background-color: var(--primary-color-100) !important;
              font-weight: 600;
              color: var(--primary-color-500) !important;
            }
          }


          &.ant-picker-cell-range-hover-end,
          &.ant-picker-cell-range-hover-start {
            &:before {
              background-color: transparent !important;
            }

            .ant-picker-cell-inner {
              color: var(--primary-color-500) !important;
              background-color: var(--primary-color-200) !important;
              border-radius: 8px 0 0 8px;
            }
          }

          &.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
            border-radius: 0 8px 8px 0 !important;
          }

          &.ant-picker-cell-range-start,
          &.ant-picker-cell-range-end,
          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: var(--primary-color-500) !important;
              border-radius: 8px 0 0 8px;
              font-weight: 600;
              color: var(--white-color-500) !important;
            }
          }

          &.ant-picker-cell-range-hover-end,
          &.ant-picker-cell-range-end {
            .ant-picker-cell-inner {
              border-radius: 0 8px 8px 0 !important;
            }
          }

          &:hover
            :not(.ant-picker-cell-range-hover-start)
            :not(.ant-picker-cell-range-hover-end)
            :not(.ant-picker-cell-range-start)
            :not(.ant-picker-cell-range-end)
            :not(.ant-picker-cell-in-range) .ant-picker-cell-inner {
            background: #f0f0f0 !important;
          }

          &.ant-picker-cell-today .ant-picker-cell-inner {
            color: var(--primary-color-500) !important;
            
            &:before { border-color: transparent; }
          }

          &:not(.ant-picker-cell-in-view) {
            opacity: .4;
          }

          .ant-picker-cell-inner {
            margin: 0;
            width: 40px;
            height: 37px;

            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--main-font-family);
  
            color: #19181a !important;
            font-weight: 400;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .locl-range-picker-popover {
    :global {
      .ant-picker-panel {
        &:not(:first-child) {
          display: none !important;
        }

        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          visibility: visible !important;
        }
      }
    }
  }
}
