.locl-hirer-incomplete-profile-message {
  background-color: var(--dark-gold-color-100);
  margin: 0;

  &__icon {
    width: 20px;
    height: 20px;
    color: var(--dark-gold-color-500);
  }

  &__link {
    font-weight: 600;
    text-decoration: underline;
  }

  :global {
    .ant-tag-close-icon {
      margin-left: auto !important;
    }
  }
}
