:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
  locl-button-link: locl-button-link;
}

.locl-location-space-info {
  width: 100%;
  padding: 24px;
  background-color: var(--white-color-500);
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);

  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  font-family: var(--main-font-family);

  &_space-page {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;

    .locl-location-space-info {
      &__header {
        order: 1;
        
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 8px;

      }
      &__note { order: 2; }
      &__description {
        order: 3;
        
        padding-bottom: 16px;
        border-bottom: 1px solid var(--secondary-color-300);
      }
      &__address { order: 4; }
    }
  }

  &_location {
    &__restricted-view {
      .locl-location-space-info {
        &__description-controls {
          pointer-events: none;
        }
      }
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--secondary-color-300);
  }

  &__header-actions {
    display: flex;
    grid-gap: 8px;

    &__btn {
      &.locl-button {
        padding: 8px;
      }
    }

    &__btn-icon {
      width: 20px;
      flex: 0 0 20px;
      height: 20px;
    }
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--secondary-color-500);
  }

  &__description {
    grid-area: description;
  }

  &__description-title {
    margin: 0;
    margin-bottom: 12px;
    padding: 0;

    font-family: var(--main-font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color-500);
  }

  &__description-label {
    font-family: var(--main-font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-color-500);

    div > span > span {
      display: block !important;
      max-width: unset !important;
    }
  }

  &__description-controls {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: var(--primary-color-900);
    padding: 8px 0;

    display: block;
    cursor: pointer;
  }

  &__note {    
    width: auto;
    max-width: 100%;
    align-self: flex-start;
    grid-area: note;
  }

  &__address {
    grid-area: address;
    
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
  }

  &__address-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 12px;

    &_clickable {
      cursor: pointer;

      .locl-location-space-info__address-item-label {
        color: var(--primary-color-900);
        text-decoration: underline;
      }
    }
  }

  &__address-item-label {
    margin: 0;
    padding: 0;

    font-family: var(--main-font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: var(--secondary-color-500);
  }

  &__address-item-icon {
    width: 20px;
    height: 20px;
    color: var(--gray-color-400);

    margin: 0;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 590px) {
  .locl-location-space-info {
    padding: 24px 16px;

    &__header {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 16px;
    }

    &__header-actions {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      width: 100%;

      &__btn-link { width: 100%; }

      .locl-button { width: 100%; }
    }

    &__links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .locl-button__link,
      .locl-button {
        width: 100%;
      }
    }
  }
}
