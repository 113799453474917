.locl-landlord-reports-chart {
   padding: 15px;

   display: flex;
   flex-direction: column;
   gap: 12px;

   background-color: var(--white-color-500);
   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.22);

   transition: all .2s;
 
   &:hover {
     box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.22);
   }

   &__title {
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
   }

   &__inner-chart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
   }

   &__inner-chart-labels {
    display: flex;
    flex-direction: column;
    gap: 12px;
   }

   &__inner-chart-label-value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
   }

   &__error-label {
    height: 270px;
    margin: 0 auto;

    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: var(--danger-color-500);

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
