:import("@/assets/styles/components/info-tooltip.module.scss") {
  locl-info-tooltip-icon: locl-info-tooltip-icon;
}

.locl-header-dropdown {
  display: flex;
  align-items: center;
  gap: 4px;
  color: inherit !important;
  font-family: var(--main-font-family);

  &__dropdown {
    :global {
      .ant-dropdown-menu {
        border-radius: 4px;
        box-shadow: 0px 4px 6px 0px rgba(31, 41, 55, 0.10), 0px 2px 4px 0px rgba(31, 41, 55, 0.06);  
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    min-width: 108px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  &__button-icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-arrow {
    &_icon {
      width: 12px;
      height: 12px;
      color: inherit;
    }
  }

  &__button-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .locl-info-tooltip-icon {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    color: inherit;
    opacity: 0.6;
  }

  :global {
    .ant-dropdown-open {
      .locl-header-dropdown__button-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &.locl-header-dropdown__currency {
    &.locl-header-dropdown__mobile {
      .locl-header-dropdown {
        &__button {
          min-width: 72px;
        }
  
        &__dropdown {
          min-width: 108px !important;
        }
      }
    }
  }

  &.locl-header-dropdown__region {
    width: max-content;
  }

  &.locl-header-dropdown__auth {
    .locl-header-dropdown {
      &__button {
        border: 1px solid var(--secondary-color-300);
      }
    }
  }
}
