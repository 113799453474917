:import("@/assets/styles/components/hirer-header-search.module.scss") {
  locl-hirer-header-search: locl-hirer-header-search;
}

:import("@/assets/styles/components/Header/base-header.module.scss") {
  locl-base-header__profile: locl-base-header__profile;
  locl-base-header__main-content: locl-base-header__main-content;
  locl-base-header__mobile-menu-button: locl-base-header__mobile-menu-button;
}

:import("@/assets/styles/components/info-tooltip.module.scss") {
  locl-info-tooltip-icon: locl-info-tooltip-icon;
}

:import("@/assets/styles/components/header-dropdown.module.scss") {
  locl-header-dropdown__region: locl-header-dropdown__region;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-hirer-header {
  padding: 0 90px 0 24px;
  background: var(--hirer-header-background-color);
  color: var(--hirer-header-text-color);
  position: static;

  @media screen and (max-width: 1440px) { padding: 0 24px }
  @media screen and (max-width: 1200px) { grid-gap: 20px; }

  &__search-block {
    display: flex;
    column-gap: 36px;
  }

  &__block {
    display: flex;
    align-items: center;
    grid-gap: 44px;

    &_last {
      width: 100%;
      
      @media screen and (max-width: 1200px) {
        justify-content: flex-end;
      }
    }
  }

  &__navigation {
    width: 100%;
  
    &__popover {
      &:global(.ant-popover) {
        :global {
          .ant-popover-inner {
            width: 278px;
            padding: 16px;
            background: var(--white-color-500);
            box-shadow: 0px 20px 25px 0px rgba(31, 41, 55, 0.10), 0px 10px 10px 0px rgba(31, 41, 55, 0.04);
            border-radius: 8px;
          }
        }
      }
    }
  }

  &__navigation-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    grid-gap: 20px;

    color: inherit;
    font-weight: 600;
    font-size: 14px;

    &__element {
      display: flex;
      align-items: center;
      grid-gap: 20px;
      white-space: nowrap;

      transition: all .2s;

      &__margin-left {
        margin-left: auto;
      }

      .locl-hirer-header-search {
        width: 220px;
        flex: 220px;
        margin: 0;
        font-weight: 400;
      }
    }

    &__element-badge {
      position: absolute;
      top: -6px;
      right: -17px;


      background-color: var(--primary-color-100);
      color: var(--primary-color-600);

      min-width: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      font-family: var(--main-font-family);
      
      padding: 2px 4px;

      &_mobile {
        position: relative;
        top: 0;
        right: 0;
        margin-left: auto;
      }
    }
  }

  &__navigation-link {
    color: inherit !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0px;
    cursor: pointer;

    position: relative;

    &:before {
      content: " ";
      position: absolute;

      transition: all .2s;

      top: calc(100% + 11px);
      width: 100%;
      height: 4px;

      background-color: var(--primary-color-500);
      opacity: 0;

      @media screen and (max-width: 1200px) {
        top: calc(100% + 5px);
      }
    }

    &_active {
      opacity: 1;

      &:before { opacity: 1; }
    }

    &__icon-wrapper {
      position: relative;
    }

    &__icon {
      width: 24px;
      height: 24px;
      transition: all .2s;
      color: var(--hirer-header-text-color);
      display: block;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__label-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    &__label {
      transition: all .2s;
      
      margin: 0;
      
      font-family: var(--main-font-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__login { margin-left: auto; }

  &__auth-buttons {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }
  
  .locl-base-header__profile { margin-left: 0; }
  .locl-base-header__main-content { flex: 1 1 auto; }
  .locl-base-header__mobile-menu-button { color: var(--hirer-header-text-color); }

  .locl-hirer-header-options {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;

    @media screen and (max-width: 1200px) {
      margin-left: auto;
      padding: 0;
    }
  }

  :global {
    .locl-header-search {
      color: var(--hirer-header-text-color);
      background-color: var(--hirer-header-search-background-color);

      &__input-container,
      &__placeholder,
      &__value-container { color: var(--hirer-header-text-color); }
      &__control { border-color: var(--hirer-header-search-border-color); }
    }
  }
}

.locl-hirer-header-logo {
  &__core {
    height: 32px;
    width: 90px;
    display: block;

    svg { height: 100%; }
  }

  &__weri {
    width: auto;
    height: 70px;
  }
}

.locl-hirer-header-mobile {
  width: 100vw;
  height: 100vh;
  padding: 24px 24px 32px 24px;

  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  background: var(--hirer-header-background-color-mobile);
  color: var(--hirer-header-text-color-mobile);

  &__logo-block {
    background: var(--hirer-header-background-color);

    margin: -25px -25px -12px;
    min-height: 72px;
    padding: 0 18px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__close-icon {
      cursor: pointer;

      width: 32px;
      height: 32px;
      color: var(--hirer-header-text-color);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__base-routes {
    list-style: none;

    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    margin: 0;
    padding: 24px 0;
    border-top: 1px solid rgba(217, 217, 217, .2);

    &__item-link {
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: normal;
      color: var(--white-color-500) !important;
    }
  }
  
  &__block {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    &__nav-account {
      padding-top: 24px;
      border-top: 1px solid rgba(217, 217, 217, 0.2);
    }

    &:last-of-type {
      grid-gap: 12px;
      margin-top: auto;
    }
  }
  
  &__logout-button {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__block-legal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;

    margin-top: 12px;

    &__link {
      color: var(--hirer-header-text-color);
      text-align: center;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__book-demo-button {
    &.locl-button {
      color: var(--white-color-500);
    }
  }

  .locl-hirer-header__navigation-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .locl-hirer-header-mobile__base-routes__item-link { text-transform: capitalize; }

  .locl-hirer-header__navigation-list__element {
    opacity: 1;
    width: 100%;

    &:nth-child(2) { margin: 0; }

    .locl-hirer-header__navigation-link {
      flex-direction: row;
      grid-gap: 8px;
      width: 100%;
      justify-content: flex-start;

      &__icon {
        width: 24px;
        height: 24px;
        opacity: 1;
      }

      &__label {
        font-size: 20px;
        line-height: normal;
        font-weight: 400;
        opacity: 1;
      }
    }
  }

  :global {
    .locl-header-search {
      color: var(--hirer-header-text-color);
      background-color: var(--hirer-header-search-background-color-mobile);
  
      &__input-container,
      &__placeholder,
      &__value-container { color: var(--hirer-header-text-color); }
    }
  }

  .locl-header-dropdown__region {
    width: 100%;
    min-width: 100%;
  }

  &_weri {
    .locl-hirer-header-mobile__base-routes { border-top: none; }
    .locl-hirer-header-mobile__logo-block { margin-top: -25px; }
  }
}
