.locl-ll-sidebar {
    position: sticky;
    top: 72px;
    background: #0c7570;
    left: 0;
    z-index: 99;
    height: calc(100vh - 72px);
    width: 240px;
    flex: 0 0 240px;
    box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
    transition: transform 0.3s, width 0.3s;
    transform: translateX(0);
    overflow-x: hidden;
  
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    * {
      font-family: var(--secondary-font-family);
    }
  }
  