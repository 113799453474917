:import("@/assets/styles/layouts/single-page-layout.module.scss") {
  locl-single-page-layout: locl-single-page-layout;
  locl-single-page-layout__content: locl-single-page-layout__content;
}
:import("@/assets/styles/shared/dropdown/dropdown-button.module.scss") {
  locl-dropdown__inner: locl-dropdown__inner;
  locl-dropdown-inner-button: locl-dropdown-inner-button;
  locl-dropdown-inner-button__value: locl-dropdown-inner-button__value;
  locl-dropdown-inner-button__icon: locl-dropdown-inner-button__icon;
}
:import("@/assets/styles/components/search-filters.module.scss") {
  locl-search-filters: locl-search-filters;
}
:import("@/assets/styles/components/pagination.module.scss") {
  locl-pagination: locl-pagination;
}
:import("@/assets/styles/components/search-map.module.scss") {
  locl-search-map: locl-search-map;
}
:import("@/assets/styles/shared/tag.module.scss") {
  locl-tag: locl-tag;
}

.locl-search-results {
  &.locl-single-page-layout {
    padding: 24px 48px 18px;
  
    @media screen and (max-width: 590px) {
      padding: 16px 16px 32px;
    }

    @media screen and (max-width: 1200px) {
      padding: 24px 16px 18px;
    }
  }

  .locl-single-page-layout__content {
    grid-gap: 12px;

    @media screen and (max-width: 1100px) {
      align-items: center;
    }
  }

  &__hidden-title {
    display: none;
  }

  &__inner {
    display: flex;
    gap: 0px;

    transition: all 0.3s;
    min-height: calc(100vh - (172px + 50px));

    &:has(:global(.ant-drawer-open)) {
      gap: 24px;
    }

    &_with-filter {
      .locl-search-results__cards__wrapper__item { grid-template-columns: repeat(3, 306px); }
    }

    &_with-map {
      .locl-search-results__cards__wrapper__item { grid-template-columns: repeat(2, 1fr); }
      .locl-search-results__cards__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }

    @media screen and (max-width: 1300px) {
      &_with-filter {
        .locl-search-results__cards__wrapper__item { grid-template-columns: repeat(2, 1fr); }
      }
  
      &_with-map {
        .locl-search-results__cards__wrapper__item { grid-template-columns: repeat(2, 1fr); }
        .locl-search-results__cards__wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    @media screen and (max-width: 1100px) {
      .locl-search-results__cards__wrapper__item {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }
      
      &_with-map {
        .locl-search-results__cards__wrapper {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }

    @media screen and (max-width: 610px) {
      .locl-search-results__cards__wrapper__item {
        grid-template-columns: 100%;
        width: 100%;
      }
    }
  }

  &__cards {
    width: 100%;

    &__empty {
      width: 100%;
      background: var(--secondary-color-300);
      height: 600px;
      color: var(--secondary-color-500);
      display: flex;

      &__content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin: auto;
        align-items: center;
        width: fit-content;
        height: fit-content;
        font-family: var(--main-font-family);

        p { margin: 0; }
        svg { font-size: 24px; }
      }

      @media screen and (max-width: 1100px) {
        height: 400px;
      }

      @media screen and (max-width: 620px) {
        height: 220px;
      }
    }

    &__controls {
      display: flex;
      column-gap: 8px;

      @media screen and (max-width: 620px) { width: 100%; }

      &__map {

        &__content {
          display: flex !important;
          align-items: center;
          grid-gap: 8px;
        }

        svg {
          stroke-width: 2;
          font-size: 18px;
        }
      }

      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        width: 100%;

        margin-bottom: 4px;

        @media screen and (max-width: 620px) {
          flex-direction: column;
        }
      }
    }

    &__controls-order {
      @media screen and (max-width: 620px) {
        display: flex;
        flex-direction: column;
        width: 100%;

        gap: 8px;

        .locl-dropdown__inner { width: 100%; }
        .locl-dropdown-inner-button { width: 100%; }
        .locl-dropdown-inner-button__value { width: auto; }
        .locl-dropdown-inner-button__icon { margin-left: auto; }
      }
    }

    &__title {
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-color-500);
      font-weight: 500;
      margin-right: auto;
      font-family: var(--main-font-family);

      margin-top: 14px;
    }

    &__wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 28px;
      justify-content: space-between;

      &__item {
        display: grid;
        grid-template-columns: repeat(4, 306px);
        grid-gap: 8px;
        transition: all 0.3s;

        &__empty-wrap {
          display: block;
          width: 100%;
        }

        @media screen and (max-width: 1300px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      
      &__map {
        position: sticky;
        top: 92px;

        flex: 1;
        animation: 0.2s show_map forwards ease;
        overflow: hidden;

        @media screen and (max-width: 1100px) { position: static; }

        @media screen and (max-width: 620px) {
          margin: 0 -15px;

          .locl-search-map { height: 420px; }
        }
      }

      @media screen and (max-width: 1100px) {
        justify-content: center;
        gap: 24px;
      }
    }

    @media screen and (max-width: 1200px) {
      max-height: none !important;
    }
  }

  &__filter_wrap {
    width: 100%;

    .locl-search-filters { margin-top: 0; }
  }

  &__pagination {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    grid-gap: 16px;

    transition: padding 0.3s;

    &_move-right {
      padding-left: 319px;

      @media screen and (max-width: 1200px) {
        padding-left: 0;
      }
    }

    .locl-search-results-select {
      width: auto !important;
      min-width: 62px;
      max-width: 200px;

      margin-left: auto;
    }

    :global {
      .locl-search-results-select {
        &__option {
          border-radius: 4px;
    
          label { margin: 0; }
        }
    
        &__menu {
          min-width: 62px;
        }
      }
    }

    @media screen and (max-width: 620px) {
      flex-direction: column;
      justify-content: center;
      grid-gap: 24px;

      &:not(:first-child) { margin-top: 12px; }

      .locl-pagination { margin-right: -8px; }
    }
  }
}

@keyframes show_map {
  0% {
    opacity: 0.1;
    height: 200px
  }

  100% {
    opacity: 1;
    height: 800px;
  }

}
