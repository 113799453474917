.locl-lolive-page-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 8px;

  height: calc(var(--window-height, 100vh) - 70px);
  min-height: calc(var(--window-height, 100vh) - 70px);
  width: 100%;
  padding: 40px;

  position: relative;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    gap: 32px;

    position: relative;
    z-index: 1;

    &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    &__title {
      color: var(--white-color-500);
      font-family: var(--main-font-family);
      font-size: 64px;
      line-height: 76px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
    }

    &__subtitle {
      color: var(--white-color-500);
      text-align: center;
      font-family: var(--main-font-family);
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      margin: 0;
    }
  }

  &__scroll-down {
    margin-top: auto;

    position: relative;
    z-index: 2;
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    background: linear-gradient(
      0deg,
      rgba(30, 30, 30, 0) 0%,
      rgba(30, 30, 30, 0.5) 26.04%,
      rgba(30, 30, 30, 0.7) 50%,
      rgba(30, 30, 30, 0.5) 75.52%,
      rgba(30, 30, 30, 0.3) 100%
    );
  }
}

@media screen and (max-width: 1200px) {
  .locl-lolive-page-banner {
    height: auto;

    &__content {
      gap: 45px;

      &__heading {
        gap: 8px;
      }

      &__title {
        font-size: 40px;
        letter-spacing: -1.2px;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .locl-lolive-page-banner {
    padding: 32px 16px 16px;

    &__content {
      width: 100%;
      grid-gap: 8px;

      &__title {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.84px;
        text-align: center;
      }

      &__subtitle {
        font-size: 20px;
      }
    }
  }
}
