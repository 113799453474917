.locl-when-dropdown-picker-overlay {
  :global {
    .ant-popover-arrow { display: none; }

    .ant-popover-inner {
      padding: 0;
      height: 0;
      width: 0;
    }
  }
}

.locl-when-dropdown-picker {
  &__root {
    :global(.ant-picker-range) {
      visibility: hidden;
      height: 0px;
      padding: 0;
      border: none;
    }
  } 

  &__popup {
    padding: 0;
    margin-top: -7px;
    inset: 10px auto auto 0px !important;

    &__panel-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      margin-left: 160px;
      margin-top: 14px;

      svg {
        margin-top: 28px;
      }
    }

    :global {
      .ant-picker-panel { border-bottom: none; }

      .ant-picker-range-arrow { display: none !important; }

      .ant-picker-cell:before {
        height: 37px;
      }

      .ant-picker-cell-today {
        &.ant-picker-cell .ant-picker-cell-inner {
          font-weight: 600;

          &:before { border: none; }
        }
      }

      .ant-picker-cell:not(.ant-picker-cell-in-view) { opacity: .5; }

      .ant-picker-cell-disabled {
        .ant-picker-cell-inner {
          color: #ccc !important;
        }

        &::before {
          background-color: transparent !important;
        }
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
        color: var(--white-color-500) !important;
        background-color: var(--primary-color-500) !important;
        font-weight: 600;
      }

      .ant-picker-cell-in-view.ant-picker-cell-in-range {
        .ant-picker-cell-inner {
          border-radius: 0;
          color: var(--primary-color-500);
          font-weight: 600;
          background-color: transparent !important;

          &:after { background-color: transparent !important; }
        }

        &::before { background: var(--primary-color-100) !important; }
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-hover-start,
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-end,
      .ant-picker-cell-in-view.ant-picker-cell-range-hover {
        .ant-picker-cell-inner { color: var(--primary-color-500); }
        &::after { border-color: transparent !important; }
      }

      .ant-picker-cell .ant-picker-cell-inner {
        align-items: center;
        display: flex;
        font-family: var(--main-font-family);
        height: 37px;
        justify-content: center;
        margin: 0;
        width: 40px;

        color: #19181a;
        font-weight: 400;
      }

      .ant-picker-panel-container .ant-picker-presets {
        padding: 8px;
        width: 160px;
        margin-top: -84px;

        ul > li {
          border-radius: 2px;
          cursor: pointer;
          font-family: var(--main-font-family);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 8px;
          margin: 0 !important;
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;

      margin: 16px 0;
    }
  }

  &__dropdown {
    background-color: red;
  }

  @media screen and (max-width: 1100px) {
    &__popup {
      inset: 10px auto auto -150px !important;
    }

  }

  @media screen and (max-width: 768px) {
    &__popup {
      inset: 10px auto auto 0px !important;

      &__panel-header {
        margin: 16px;
      }

      &__footer {
        padding: 0 16px;
      }

      :global {
        .ant-picker-panel-layout {
          display: flex;
          flex-direction: column-reverse;
        }
        .ant-picker-panel-container .ant-picker-presets {
          margin-top: 0;
          width: 100%;

          ul {
            height: max-content;
            border: none;
          }
        }
  
        .ant-picker-panel {
          &:last-child {
            width: 0;
            
            .ant-picker-header {
              position: absolute;
              right: 0;
  
              .ant-picker-header-prev-btn, .ant-picker-header-view { visibility: hidden; }
            }
            .ant-picker-body { display: none; }      
          }
        }
      }
    }
  }
}
