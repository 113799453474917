:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-billing-details-card {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--secondary-color-300);
  background: var(--secondary-color-200);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 16px;

    &__info {
      display: flex;
      align-items: center;
      grid-gap: 8px;
    }

    &__action {
      display: flex;
      align-items: center;
      grid-gap: 4px;
    }

    &__edit,
    &__delete {
      &.locl-button {
        width: 36px;
        height: 36px;
        padding: 7px;
      }

      &__icon {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
      }
    }

    &__edit__icon {
      color: var(--secondary-color-500);
    }

    &__delete__icon {
      color: var(--danger-color-500);
    }
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--secondary-color-500);
  }

  &__default {
    padding: 2px 4px;
    border-radius: 4px;
    background: var(--primary-color-100);

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: var(--primary-color-500);
  }

  &__body {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: var(--secondary-color-500);
    }

    &__details {
      display: flex;
      flex-direction: column;
      grid-gap: 4px;

      &__item {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--gray-color-500);
      }
    }
  }
}

.locl-billing-details-card-loader {
  &__title {
    &:global(.ant-skeleton-element .ant-skeleton-input ),
    &:global(.ant-skeleton-element) {
      height: 28px !important;
      min-width: 128px !important;
      width: 100% !important;
      border-radius: 0px;
      border: 1px solid var(--gray-color-300);
    }
  }

  &__action-button {
    &:global(.ant-skeleton-element .ant-skeleton-input ),
    &:global(.ant-skeleton-element) {
      height: 36px !important;
      min-width: 36px !important;
      width: 36px !important;
      border: 1px solid var(--gray-color-300);
    }
  }

  &__block {
    &__title {
      &:global(.ant-skeleton-element .ant-skeleton-input ),
      &:global(.ant-skeleton-element) {
        height: 24px !important;
        border-radius: 0px;
        border: 1px solid var(--gray-color-300);
      }
    }
    &__details__item {
      &:global(.ant-skeleton-element .ant-skeleton-input ),
      &:global(.ant-skeleton-element) {
        height: 20px !important;
        border-radius: 0px;
        border: 1px solid var(--gray-color-300);
      }
    }
  }
}
