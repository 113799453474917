.locl-base-layout {
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
