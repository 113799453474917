:import("@/assets/styles/components/Form/abstract-event-form.module.scss") {
  locl-abstract-event-form-campaign__footer: locl-abstract-event-form-campaign__footer;
  locl-abstract-event-form-option-info__footer: locl-abstract-event-form-option-info__footer;
}

:import("@/assets/styles/containers/abstract-event.module.scss") {
  locl-abstract-event__form: locl-abstract-event__form;
  locl-abstract-event-steps: locl-abstract-event-steps;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

:import("@/assets/styles/layouts/single-page-layout.module.scss") {
  locl-single-page-layout: locl-single-page-layout;
  locl-single-page-layout__content: locl-single-page-layout__content;
}

.locl-option-create {
  &.locl-single-page-layout {
    padding-top: 16px;
  }

  .locl-single-page-layout__content {
    grid-gap: 16px;
  }

  &__go-back {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
    max-width: max-content;

    &__icon {
      width: 24px;
      height: 24px;
      color: var(--secondary-color-500);
    }

    &__text {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      color: var(--secondary-color-500);
    }
  }

  &__block {
    display: block;
  }

  .locl-abstract-event-steps {
    display: flex;
    justify-content: flex-start;
    grid-gap: 16px;
  }
}

.locl-option-create-success-modal {
  :global {
    .ant-modal-confirm-btns {
      text-align: center;
    }
  }

  &__hint {
    display: block;
    margin-bottom: 8px;

    color: var(--gray-color-500);
  }
}

@media screen and (max-width: 767px) {
  .locl-option-create {
    padding-bottom: 0 !important;

    &__go-back {
      width: 100%;

      &__text {
        flex: 1 1 0;
        text-align: center;
      }
    }

    .locl-abstract-event__form {
      margin-bottom: 77px;
    }

    .locl-abstract-event-form-campaign,
    .locl-abstract-event-form-option-info {
      &__footer {
        position: sticky;
        bottom: 0;
        padding: 16px 24px;
        margin: 0 -40px -90px;
        background: var(--white-color-500);
        box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.1);
        border-top: 1px solid var(--Grey-300, var(--secondary-color-400));

        .locl-button {
          flex: 1 1 0;
        }
      }
    }
  }
}

@media screen and (max-width: 590px) {
  .locl-option-create {
    .locl-abstract-event-form-campaign,
    .locl-abstract-event-form-option-info {
      &__footer {
        position: sticky;
        bottom: 0;
        padding: 16px 12px;
        margin: 0 -25px -90px;
      }
    }
  }
}
