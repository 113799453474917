.locl-ll-sidebar-terms {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  padding-top: 0;
  align-items: flex-start;
  justify-content: flex-start;

  &__link {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    padding: 0px 20px;
    overflow: hidden;
    background: transparent;
    border: none;

    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 23px;
    text-decoration: underline;
    color: #FFFFFF;

    &:hover {
      text-decoration: none;
      color: #3ea3fc;
    }
  }

  &__copyright {
    color: #cbcbcb;
    font-size: 12px;
    line-height: 19px;
    padding: 10px 20px;
  }
}
