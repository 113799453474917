:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
  locl-button__link: locl-button__link;
}

.locl-header-profile-dropdown {
  margin-left: auto;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  grid-gap: 2px;

  padding: 4px 8px;
  border-radius: 4px;
  color: inherit !important;

  &:hover {
    background: rgba(255, 255, 255, 0.1)
  }

  &_dropdown {
    :global {
      .ant-dropdown-menu {
        margin-top: 12px;
        // width: 160px;
        width: 240px;
        padding: 8px;
        
        .ant-dropdown-menu-item {
          padding: 8px;

          
        }
      }
    }
    
    &__item {
      display: flex;
      column-gap: 8px;
      font-weight: 500;
      color: var(--secondary-color-500);

      &__book-demo {
        color: var(--white-color-500) !important;
      }

      &__logout {
        color: var(--danger-color-500) !important;
      }

      &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 20px;
          stroke-width: 2.5;
          width: 100%;
          height: 100%;
        }
      }

      &__badge {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto 0 auto auto;

        height: 20px;
        width: 32px;
        border-radius: 4px;
        padding: 2px 4px;
        background: var(--primary-color-100);

        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        color: var(--primary-color-500);
      }

      &.locl-button__link {
        width: 100%;

        .locl-button {
          width: 100%;
        }
      }
    }

    &__button {

      &:hover {
        background: transparent !important;
      }

      :global {
        .ant-dropdown-menu-title-content > a {
          &::after { content: none !important; }
        }
      }
    }
  }

  &_text-hello {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.6;
  }

  &_text-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: auto;
    max-width: 103px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &_info {
    display: flex;
    align-items: center;
    grid-gap: 4px;
  }

  &_arrow {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_icon {
      width: 12px;
      height: 12px;
      color: inherit;
    }
  }
}
