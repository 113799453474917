:import("@/assets/styles/shared/tag.module.scss") {
  locl-tag: locl-tag;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

:import("@/assets/styles/pages/lolive/lolive-search-bar.module.scss") {
  locl-lolive-search-bar: locl-lolive-search-bar;
}


.locl-westfield-home-page {
  flex: 1 1 auto;
  min-height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    color: #fff;
    font-family: var(--landing-font-family);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 49px;
    margin: 0;
  }

  &__subtitle {
    color: #fff;
    font-family: var(--landing-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
    text-align: center;

    margin-bottom: 24px;
  }

  &__main {
    margin-top: -72px;

    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__quick-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    &__nested-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 12px;

      .locl-tag span {
        font-family: var(--landing-font-family);
        font-weight: 400;
      }
    }

    &__list-link {
      width: max-content;
    }

    &__hint {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      margin: 8px 0;

      font-family: var(--landing-font-family);
      font-weight: 400;
    }
  }

  &__all-locations {
    border-radius: 30px;

    span {
      font-family: var(--landing-font-family);
      font-weight: 400;
    }
  }

  &__tag {
    width: max-content;
  }

  .locl-lolive-search-bar {
    .locl-button span {
      font-family: var(--landing-font-family);
      font-weight: 400;
    }
  }

  :global {
    .locl-search-filters-field__label {
      font-family: var(--landing-font-family);
      font-weight: 400;
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 32px 16px 40px;
    
    img { object-fit: cover; }

    &__title {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 29px;
    }
  }
}

.locl-when-dropdown-picker-overlay-weri {

  * {
    font-family: var(--landing-font-family);
    font-weight: 400;
  }
}
