.locl-infinite-marquee {
  padding: 20px;

  :global(.rfm-marquee-container),
  :global(.rfm-marquee),
  :global(.rfm-initial-child-container) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
  }

  &__item {
    color: #ffffff;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;

    a {
      color: inherit;
    }

    &:after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;

      background-color: #ffffff;
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 820px) {
  .locl-infinite-marquee {
    gap: 24px;

    &__item {
      font-size: 16px;
      line-height: 24px;
      gap: 24px;
    }
  }
}
