:import("@/assets/styles/layouts/single-page-layout.module.scss") {
  locl-single-page-layout: locl-single-page-layout;
  locl-single-page-layout__content: locl-single-page-layout__content;
}


.locl-go-search {
  &__wrapper {
    &.locl-single-page-layout { padding: 32px 0 18px; }

    .locl-single-page-layout__content { grid-gap: 32px; }

    & > .locl-popular-destinations, .locl-search-filters, .locl-environment-carousel, .locl-trending-locations {
      width: 100%;
    }
  }
}

@media screen and (max-width: 590px) {
  .locl-go-search {
    &__wrapper {
      &.locl-single-page-layout { padding: 16px 16px 32px; }
    }
  }
}
