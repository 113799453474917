.locl-legal-page-layout {
  min-height: 100vh;
  background-color: var(--gray-color-300);
  scroll-behavior: smooth; 

  &__header {
    background: var(--auth-header-background-color);
    position: sticky !important;
    top: 0 !important;
  }

  &__body {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    grid-gap: 24px;
  
    max-width: 1128px;
    margin: 0 auto;
    padding: 32px 0;

    @media screen and (max-width: 820px) {
      grid-template-columns: 100%;
    }
  }

  &__content {
    width: 100%;

    background: var(--white-color-500);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;

    padding: 16px;

    font-family: var(--main-font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--secondary-color-500);

  }

  :target::before {
    content: '';
    display: block;
    height: 88px;
    margin-top: -88px;
  }
}
