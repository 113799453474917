.locl-tag {
  &:global(.ant-tag) {
    padding: 4px 8px;
    margin: 0;

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: break-spaces;

    color: var(--secondary-color-500);
    background: var(--white-color-500);
    border: 1px solid transparent;
    border-radius: 4px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 6px;

    :global {
      svg {
        flex: 0 0 18px;
        width: 18px;
        height: 18px;
      }
    }
  }

  &_color-indigo {
    &:global(.ant-tag) {
      color: var(--white-color-500);
      background-color: var(--indigo-color-500);
    }
  }

  &_color-red {
    &:global(.ant-tag) {
      color: var(--white-color-500);
      background-color: var(--rose-color-500);
    }
  }

  &_color-green {
    &:global(.ant-tag) {
      color: var(--white-color-500);
      background-color: var(--primary-color-500);
    }
  }

  &_color-yellow {
    &:global(.ant-tag) {
      color: var(--secondary-color-500);
      background-color: var(--yellow-color-400);
    }
  }

  &_color-blue {
    &:global(.ant-tag) {
      color: var(--white-color-500);
      background-color: var(--blue-color-500);
    }
  }

  &_color-gray {
    &:global(.ant-tag) {
      color: var(--white-color-500);
      background-color: var(--secondary-color-500);
    }
  }

  &_color-dark-gold {
    &:global(.ant-tag) {
      color: var(--secondary-color-500);
      background-color: var(--dark-gold-color-100);
    }

    &:global(.ant-tag > svg) {
      color: var(--dark-gold-color-500);
    }
  }

  &_color-dust {
    &:global(.ant-tag) {
      color: var(--secondary-color-500);
      background-color: var(--dust-color-100);
    }

    &:global(.ant-tag > svg) {
      color: var(--dust-color-500);
    }
  }

  &_fade {
    &.locl-tag_color-indigo {
      &:global(.ant-tag) {
        color: var(--indigo-color-500);
        background-color: var(--indigo-color-100);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }

    &.locl-tag_color-red {
      &:global(.ant-tag) {
        color: var(--danger-color-500);
        background-color: var(--danger-color-300);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }

    &.locl-tag_color-green {
      &:global(.ant-tag) {
        color: var(--primary-color-500);
        background-color: var(--primary-color-100);
      }
    }

    &.locl-tag_color-yellow {
      &:global(.ant-tag) {
        color: var(--amber-color-500);
        background-color: var(--yellow-color-100);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }

    &.locl-tag_color-blue {
      &:global(.ant-tag) {
        color: var(--primary-color-500);
        background-color: var(--primary-color-100);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }

    &.locl-tag_color-gray {
      &:global(.ant-tag) {
        color: var(--secondary-color-500);
        background-color: var(--secondary-color-300);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }

    &.locl-tag_color-light-gray {
      &:global(.ant-tag) {
        color: var(--secondary-color-500);
        background-color: var(--gray-color-300);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }

    &.locl-tag_color-white {
      &:global(.ant-tag) {
        border-color: var(--secondary-color-300);
      }

      .locl-tag__text {
        color: var(--secondary-color-500);
      }
    }
  }

  &__close-icon {
    width: 20px;
    height: 20px;
  }

  &_size-small {
    &:global(.ant-tag) {
      padding: 2px 6px;

      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  :global {
    .ant-tag-close-icon {
      margin: 0 !important;
      margin-left: auto !important;
      width: 20px;
      height: 20px;
      color: inherit !important;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
