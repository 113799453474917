.locl-hirer-header-search {
  width: 280px;
  flex: 0 0 280px;
  margin-left: -12px;
}

.locl-header-select-option {
  &__body {
    display: flex;
    grid-gap: 8px;
    width: 100%;
  }

  &__text {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__tooltip {
    .ant-tooltip-inner {
      border-radius: 4px;
      background-color: var(--black-color-500);
      padding: 8px;
  
      font-family: var(--main-font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--white-color-500);
    }
  
    .ant-tooltip-arrow-content::before { background-color: var(--black-color-500); }
  }

  &__message {
    padding: 8px;
    background: var(--primary-color-100) !important;
    opacity: 1 !important;
    
    &__text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: var(--secondary-color-500);

      text-wrap: wrap;
    }
  }

  &:global(.locl-header-search__option) {
    padding: 8px;
  }
}

.locl-header-select-group {
  &:global(.locl-header-search__group) {
    margin: 0;
    padding: 0;
  }
}

.locl-header-select-group-label {

  &:global(.locl-header-search__group-heading) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    
    padding: 8px;
    margin-bottom: 0;
    background: var(--gray-color-300);
    color: var(--secondary-color-500);
  }
}

@media screen and (max-width: 1200px) {
  .locl-hirer-header-search {
    width: 100%;
    flex: auto;
    margin-left: 0;
  }

  .locl-header-select-option {
    &__tooltip { display: none; }
  }
}
