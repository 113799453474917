.locl-lolive-search-bar {
  max-width: 950px;

  display: grid;
  grid-template-columns: 1fr auto 1fr  auto;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  
  padding: 8px 16px;

  border-radius: 12px;
  background: linear-gradient(91deg, rgba(255, 255, 255, 0.30) 3.76%, rgba(27, 28, 47, 0.30) 99.32%);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(35.33766555786133px);

  &__column-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    &:not(&:last-of-type)::after {
      content: " ";
      height: 32px;
      width: 1px;
      
      opacity: .2;
      
      background-color: #E5E7EB;
    }
  }

  &__button-icon {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }

  :global {
    .locl-search-filters-field {
      box-shadow: none !important;
      border: none !important;
    }
  }
}

.locl-lolive-search-bar-select {
  background-color: transparent !important;
  width: auto !important;

  &__control {
    height: auto;
    border: none !important;
    box-shadow: none !important;
  }

  &__value-container { margin-top: 24px; }

  &__option {
    color: #7d7d7d;
    font-family: "Inter", sans-serif;
    letter-spacing: 0;
    font-size: 15px;
    line-height: 19px;

    padding: 8px;

    transition: all 0.2s ease-in-out;
    
    label { margin: 0; }

    &:hover {
      background-color: #f8f7fa !important;
      color: #fe6161;
      font-weight: 700;
    }
  }

  &__value-preview {
    position: relative;

    &_hidden {
      cursor: pointer;

      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      opacity: 0;
    }

    &_hidden:hover + .locl-search-bar-preview .locl-search-bar-preview__inner {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.locl-search-bar-preview {
  display: flex;
  flex-direction: column;
  margin: 0;

  &__label {
    color: #F3F4F6;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    
    border-radius: 4px;
    padding: 4px 12px 4px 4px;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover { background-color: rgba(255, 255, 255, 0.3); }

    svg {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      opacity: 0.5;
    }
  }

  &__value {
    display: inline-block;

    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1200px) {
  .locl-lolive-search-bar {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding: 0;
    border-radius: 0;
    width: 100%;
    max-width: 400px;
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;

    &__column-cell {
      gap: 0;
      padding: 8px 12px;
      border-radius: 12px;
      background: linear-gradient(91deg, rgba(255, 255, 255, 0.30) 3.76%, rgba(27, 28, 47, 0.30) 99.32%);
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.22);
      backdrop-filter: blur(35.33766555786133px);
      position: relative;

      :global {
        .ant-dropdown-trigger { width: 100%; }
      }

      &:not(&:last-of-type)::after { content: none; }
    }

    .locl-multi-select {
      .dropdown-area {
        left: 50%;
        transform: translateX(-50%);
      }
      
      .selectWrapper {
        .selector_labels { max-width: 376px; }
        .selector_labels-arrow { margin-left: auto; }
      }
    }

    .locl-date-picker-search {
      width: 100%;

      &__preview { width: 100%; }
      &__label { margin-left: 4px; }
      &__value { max-width: 376px; }

      .calendar-wrapper {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
    }

    .locl-lolive-search-bar-select {
      width: 100% !important;

      &__value-preview { width: 100%; }
    }
    
    .locl-search-bar-preview {
      &__label { margin-left: 4px; }
      &__arrow { margin-left: auto; }
      &__value { 
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .locl-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1050px) {
  .locl-lolive-search-bar {
    &__column-cell {
      .locl-multi-select {
        .selectWrapper {
          backdrop-filter: none;
          background: transparent;
          align-items: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .locl-lolive-search-bar { margin-bottom: auto; }
}

@media screen and (max-width: 520px) {
  .locl-lolive-search-bar {
    max-width: 100%;

    &__column-cell {
      .locl-multi-select {
        .selectWrapper {
          .selector_labels { max-width: calc(100vw - 56px); }
          .selector_labels-arrow { margin-left: auto; }
        }
      }

      .locl-search-bar-preview {
        &__label { margin-left: 4px; }
        &__arrow { margin-left: auto; }
        &__value { max-width: calc(100vw - 120px); }
      }
    }
  }
}
