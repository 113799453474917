// .locl-search-filters-field-wrapper {
//   &_column-view {
//     .locl-search-filters-field__error {
//       top: calc(100% + 2px);
//     }
//   }
// }

.locl-search-filters-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;

  position: relative;
  z-index: 2;

  max-width: 210px;
  padding: 8px 12px;
  margin-right: 10px;

  border-radius: 4px;
  border: 1px solid transparent;
  
  transition: all .2s;
  cursor: pointer;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    height: 100%;
    width: 1px;
    background-color: transparent;
    z-index: 1;
  }

  &.ant-dropdown-open {
    background-color: var(--secondary-color-200);

    .locl-search-filters-field__label {
      background-color: transparent;
    }
  }

  &_with-value {
    padding: 8px 4px;
    transform: translate(0, 10px);

    .locl-search-filters-field__label {
      position: absolute;
      z-index: 3;

      transform: translate(-28px, -26px) scale(1);
    }

    &::after { top: -10px; }
  }

  &_with-error {
    border: 1px solid var(--rose-color-500);
    box-shadow: 0px 0px 0px 3px rgb(255, 228, 230);
  }

  &__error {
    position: absolute;
    top: calc(100% + 2px);

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--rose-color-500);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 4px;
    color: var(--white-color-500);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    flex: 0 0 20px;

    color: var(--gray-color-400);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__label,
  &__value {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    margin-bottom: 0;

    cursor: pointer;

    &__additional {
      color: var(--secondary-color-500);
      line-height: 16px;
      white-space: nowrap;
    }
  }

  &__label {
    transition: all .2s;
    background-color: transparent;

    &-optional {
      font-size: 12px;
      margin-left: 4px;
    }
  }

  &__value {
    color: var(--white-color-500);
  }

  &__arrow-indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }
}

@media screen  and (max-width: 1200px) {
  .locl-search-filters-field {
    position: static;
    max-width: 100%;
    margin-right: 0;

    &:after { display: none; }

    &.ant-dropdown-open {
      background-color: transparent;
      border: 1px solid var(--primary-color-500);
      border-radius: 4px;
      box-shadow: 0 0 0 2px rgba(113, 211, 205, 0.3);
    }

    &__label {
      max-width: 135px;
    }

    &__value {
      font-weight: 500;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;

      &__additional {
        font-weight: 500;
      }
    }

    &__arrow-indicator {
      position: absolute;
      right: 26px;
    }

    &__inner {
      grid-gap: 8px;
      justify-content: flex-start;
    }

    &__error {
      top: 35px;
      font-size: 12px;
    }

    &:not(.locl-search-filters-field_column-view) {
      .locl-search-filters-field_with-value {
        padding: 8px 42px 8px 12px;
        transform: initial;
  
        .locl-search-filters-field__label {
          position: static;
          transform: initial;
        }
      }
    }

    &.locl-search-filters-field_column-view {
      &.locl-search-filters-field_with-value {
        height: 56px;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .locl-search-filters-field {
    &__label {
      max-width: 105px;
    }
    
    &__value {
      max-width: 125px;
    }
  }
}
