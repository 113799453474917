.locl-availability-calendar-cell-inner {
  position: relative;
  height: 84px;
  padding: 8px;
  margin: 0;
  vertical-align: top;
  text-align: left;
  border: none;
  border: 1px solid var(--gray-color-300);

  z-index: 1;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--black-color-500);

  &:not(.locl-availability-calendar-cell-inner_selected-range):hover {
    &:not(.locl-availability-calendar-cell-inner_status-closed) {
      background-color: var(--primary-color-100);
      border: 1px solid var(--primary-color-500);
    }

    &.locl-availability-calendar-cell-inner_unknown {
      border: 1px solid var(--orange-color-500);
      background-color: var(--orange-color-350);
    }
  }

  &__value {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: inherit;
  }

  &__note {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    color: var(--danger-color-500);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 5px;
    right: 5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_start {
    background-color: var(--primary-color-500);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: var(--white-color-500);
  }

  &_end {
    background-color: var(--primary-color-500);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--white-color-500);
  }

  &_range {
    color: var(--black-color-500);
    background-color: var(--primary-color-100);
  }


  &_unavailable {
    &.locl-availability-calendar-cell-inner_end,
    &.locl-availability-calendar-cell-inner_start {
      color: var(--white-color-500);
      background-color: var(--gray-color-400);
    }

    &.locl-availability-calendar-cell-inner_range { background-color: var(--gray-color-300); }
  }

  &_is-outside-day .locl-availability-calendar-cell-inner__value {
    color: rgba(0, 0, 0, .4);
  }

  &_status-closed {
    cursor: not-allowed;

    border: 1px solid var(--secondary-color-300);
    background-image: linear-gradient(-45deg, var(--gray-color-300) 25%, transparent 25%, transparent 50%, var(--gray-color-300) 50%, var(--gray-color-300) 75%, transparent 75%, transparent);
    background-size: 20px 20px;
  }

  &_unknown {
    &.locl-availability-calendar-cell-inner_end,
    &.locl-availability-calendar-cell-inner_start {
      color: var(--white-color-500);
      background-color: var(--corvette-color-500);
    }

    &.locl-availability-calendar-cell-inner_range { background-color: var(--corvette-color-050); }
  }

  @media screen and (max-width: 720px) {
    height: 52px;
    padding: 4px;

    &__value {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}

.locl-availability-calendar-cell {
  width: calc(100% + 18px);
  height: 34px;

  margin-left: -9px;
  margin-bottom: -7px;

  position: relative;
  z-index: 2;

  transition: all 0.2s;

  &_status-start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &_status-end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-left: -9px;
  }

  &_status-booked { background-color: var(--danger-color-500); }
  &_status-unavailable { background-color: var(--gray-color-600); }
  &_status-under-option { background-color: var(--yellow-color-500); }

  &__day-activity {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--white-color-500);

    background-color: var(--secondary-color-500); 

    height: 20px;
    width: calc(100% + 2px);
    padding: 2px 12px;

    position: absolute;
    bottom: 0;
    left: -1px;
    z-index: 2;

    border-top: 2px solid var(--white-color-500);
    border-bottom: 2px solid var(--white-color-500);

    &_start-range {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 1px;
      width: 100%;

      border-left: 2px solid var(--white-color-500);
    }

    &_end-range {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      width: calc(100%);

      border-right: 2px solid var(--white-color-500);
    }
  }

  @media screen and (max-width: 720px) {
    height: 16px;
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-bottom: -4px;

    &_status-end {
      width: calc(100% + 9px);
    }

    &__day-activity {
      height: 16px;
      height: 10px;

      font-size: 0;
    }
  }
}
