:import("@/assets/styles/components/availability-calendar/availability-calendar-cell.module.scss") {
  locl-availability-calendar-cell-inner: locl-availability-calendar-cell-inner;
  locl-availability-calendar-cell: locl-availability-calendar-cell;
}


.locl-availability-calendar {
  background: var(--white-color-500);
  border: 1px solid var(--secondary-color-300);
  border-radius: 4px;
  position: relative;

  max-width: 714px;
  margin: 0 auto;

  &__skeleton {
    &:global(.ant-skeleton-element .ant-skeleton-input ),
    &:global(.ant-skeleton-element) {
      height: 84px !important;
      min-width: 20px !important;
      width: 100% !important;
      border-radius: 0px;
      border: 1px solid var(--gray-color-300);
    }
  }

  &__type-space {
    border: none;
    max-width: none;
    flex: none;
    margin: 0 auto;
    border: none;

    .locl-availability-calendar-cell-inner {
      height: 92px;
    }

    .locl-availability-calendar-cell {
      height: 32px;
    }
  }

  &__space-type-b {
    &.locl-availability-calendar {
      :global {
        .ant-picker-panel {
          .ant-picker-body {
            th {
              color: var(--corvette-color-700);
              background-color: var(--corvette-color-050);
            }
          }
        }
      }
    }
  }
  
  :global {
    .ant-picker-panel {
      .ant-picker-body {
        th {
          width: 100% !important;
          text-align: left;
          background-color: var(--secondary-color-200);
          padding: 8px 8px 10px 8px !important;
          font-family: var(--main-font-family);
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
          color: var(--gray-color-400);
          text-transform: uppercase;
          border: 1px solid var(--gray-color-300);
          border-bottom: none;
        }

        .ant-picker-cell-inner {
          transition: all .2s;

          &.ant-picker-calendar-date-today {
            background-color: transparent !important;
          }
        }

        .ant-picker-cell-disabled {
          cursor: not-allowed;
          pointer-events: all;

          .locl-availability-calendar-cell-inner {
            border: 1px solid var(--gray-color-300);
            opacity: .7;

            .locl-availability-calendar-cell-inner__value {
              color: var(--gray-color-400) !important;
            }
          }
        }

        .ant-picker-content tbody {
          tr:last-of-type { display: none; }
        }
      }
    }
  }
}
