.locl-where-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 820px;

  border-radius: 12px;
  overflow: hidden;
  background-color: var(--white-color-500);

  &__dropdown-tree {
    color: var(--secondary-color-500);
    width: fit-content;
    position: relative;
    padding-top: 4px;
  }

  &__dropdown-tree-mobile-wrapper-header {
    display: flex;
    flex-direction: column;
    margin-bottom: -8px;
  }
  
  &__dropdown-go-back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 8px;

    padding: 0 14px;
    color: var(--gray-color-400);
    cursor: pointer;
  }

  &__dropdown-menu-mobile {
    margin-left: 4px;
  }

  &__dropdown-area {
    padding-bottom: 8px;
  }

  &__labels-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 8px;
    grid-row-gap: 4px;
    padding: 8px;

    &__item {
      &:global(.locl-tag.ant-tag) {
        background-color: var(--danger-color-500);
        color: var(--white-color-500);
        margin: 0;
      }
    }
  }

  &__search-container {
    width: 100%;
  }

  &__search-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;

    padding: 16px 14px;
  }

  &__search-input {
    border: none;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--secondary-color-500);

    &::placeholder {
      color: var(--gray-color-400);
    }

    &:hover, &:focus, &:focus-visible {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  &__clear {
    width: 20px;
    height: 20px;
    padding: 1px;
    
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;


    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (max-width: 830px) {
    width: 100%;

    &__dropdown-tree {
      width: 100%;
    }
  }
}

.locl-where-select-item {
  min-width: 240px;
  max-width: 284px;
  height: 36px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 8px;
  margin: 8px 0px 8px 8px;
  
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.1s ease;

  background-color: var(--white-color-500);
  border-radius: 4px;

  &:hover {
    background: var(--gray-color-300);
  }

  &__content {
    width: 100%;
    height: fit-content;

    &_open {
      opacity: 1;
      pointer-events: all;
      transition: 0.2s;
    }
  }

  &__inner {
    width: 100%;
    flex: 0 0 100%;

    :global(span:not(.ant-checkbox)) {
      width: 100%;
    }

    &_opened {
      .locl-where-select-item__inner-arrow {
        transform: rotate(-90deg);
      }
    }

    .ant-checkbox + span {
      flex: 1 1 auto;
      padding: 0;
      padding-left: 8px;
    }
  }

  &__inner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;
  }

  &__inner-arrow {
    width: 24px !important;
    height: 24px;
    padding: 6px;

    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--gray-color-400);
    transition: all .2s;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__children {
    display: flex;
    width: fit-content;
    flex-direction: column;
    position: absolute;
    left: 100%;
    top: 0;

    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    max-height: 0;

    &_open {
      opacity: 1;
      pointer-events: all;
      transition: 0.2s;
      max-height: unset;
    }
  }

  @media screen and (max-width: 830px) {
    width: unset;
    max-width: unset;
    margin: 8px;
  }  
}
