// load-in-into-header

:import("@/assets/styles/components/header-dropdown.module.scss") {
  locl-header-dropdown: locl-header-dropdown;
  locl-header-dropdown__region: locl-header-dropdown__region;
}

:import("@/assets/styles/components/hirer-header-search.module.scss") {
  locl-hirer-header-search: locl-hirer-header-search;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-auth-header {
  &:global(.locl-base-header) {
    position: sticky;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--auth-header-background-color);
    color: var(--auth-header-text-color);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);

    padding: 0 24px;
    justify-content: flex-start;
    transition: all 0.5s;
  
  }

  &__navigation-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;

    &_offset { margin-left: 70px; }
  }

  &__profile-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 12px;
  }

  &__logo {
    display: flex;

    &_weri {
      display: flex;
      height: 68px;
      width: 125px;
      flex: 0 0 125px;
    }

    &_weri-img {
      object-fit: cover;

      width: 100%;
      height: 100%;
    }

    svg {
      height: 32px;
    }
  }

  &__logo-img {
    width: 100%;
    height: 42px;

    display: flex;
    align-items: center;

    svg {
      height: 32px;
    }
  }

  &__nav-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 32px;
  }

  &__nav-list-item {
    width: 100%;
  }

  &__nav-item {
    color: var(--secondary-color-500);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;

    &:hover,
    &:active {
      color: var(--danger-color-500);
    }

    &__active {
      color: var(--danger-color-500) !important;
    }

    &__label {
      font-family: var(--secondary-font-family);
    }

    &__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0 auto auto;

      height: 20px;
      width: 32px;
      border-radius: 4px;
      padding: 2px 4px;
      background: var(--primary-color-100);

      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: var(--primary-color-500);
    }
  }

  &__auth-buttons {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  .locl-header-dropdown__region {
    min-width: 120px;
  }
  
  .locl-hirer-header-search { margin: 0; }
}

.locl-auth-header-mobile {
  width: 100vw;
  height: 100vh;
  padding: 20px 20px;

  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  background: var(--auth-header-background-color-mobile);
  color: var(--auth-header-text-color-mobile);

  &__logo-block {
    background: var(--auth-header-background-color);

    margin: -20px -20px -12px;
    padding: 0 20px;
    min-height: 72px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__close-icon {
      cursor: pointer;

      width: 32px;
      height: 32px;
      color: var(--auth-header-text-color-mobile); 

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
    
  &__block {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    &:last-of-type {
      grid-gap: 12px;
      margin-top: auto;
    }
  }

  &__copyright {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #a1a1aa;
    margin: 0;
  }

  &__divider {
    position: relative;
    width: 100%;
    padding-bottom: 1px;
    background: var(--gray-color-400);
    background-color : var(--gray-color-400);
    opacity: 0.2;
  }

  &__logout-button {
    &.locl-button {
      border: none;
      box-shadow: none;
    }
  }

  .locl-auth-header {
    &__nav-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 16px;
    }

    &__nav-item {
      flex-direction: row;
      grid-gap: 8px;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 24px;
        height: 24px;
        flex: 0 0 24px;

        svg {
          width: 100%;
          height: 100%;
        }

        :global {
          .locl-auth-header__menu-icon {
            width: 24px;
            height: 24px;
            display: flex;
          }
        }
      }

      &__label {
        font-family: var(--main-font-family);
        font-size: 20px;
        line-height: 32px;
        margin: 0;
      }
    }
  }

  .locl-header-dropdown__region {
    min-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .locl-auth-header {
    &:global(.locl-base-header) {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .locl-auth-header {
    &__search-block {
      :global {
        .locl-hirer-header-search {
          width: 200px;
          min-width: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .locl-auth-header {
    &:global(.locl-base-header) {
      padding: 0 20px;
      grid-gap: 0;
    }
  }
}
  