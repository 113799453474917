.locl-ll-sidebar-contacts {
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #cbcbcb;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 11px 20px;

  &__link {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background: transparent;
    border: none;

    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 23px;
    text-decoration: unset;
    color: #FFFFFF;

    &:hover {
      color: #FFFFFF;
    }
  }
}
