.locl-ll-sidebar-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  padding: 11px 20px;
  margin: 10px 0;
  overflow: hidden;
  background: transparent;
  border: none;

  letter-spacing: 0.5px;
  
  &:hover {
    background-color: rgba(19, 181, 172, 0.45);
  }
  
  &.active {
    border-left: 3px solid #fe6161;

    div {
      color: white;
      font-weight: 600;
    }
  }

  &__disabled {
    &:hover {
      background-color: transparent;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    padding: 11px 20px;
    margin: 10px 0;
    overflow: hidden;
    background: transparent;
    border: none;
    
    &:hover {
      background-color: transparent;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    
    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 23px;
    text-decoration: unset;
    color: #FFFFFF;

    &__disabled {
      color: #79B3B0;
    }
  }

  &__badge {
    display: inline-block;
    width: 26px;
    height: 14px;
    background-color: #ff4861;
    font-size: 8px;
    font-weight: 400;
    padding: 2px;
    margin-left: 5px;
    line-height: 9px;
    position: relative;
    text-transform: uppercase;
    border-radius: 7px;
    color: #fff;
    text-align: center;

    &__text {
      position: absolute;
      left: 0;
      top: 3px;
      width: 26px;
      text-align: center;
    }
  }
}
