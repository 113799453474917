.locl-search-tabs {
  display: flex;
  margin-bottom: -1px;

  &__block {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    padding: 8px 16px;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--gray-color-500);

    cursor: pointer;

    &:hover { color: var(--gray-color-500); }

    &_active {
      background: var(--white-color-500);
      border-color: var(--secondary-color-300);
      font-weight: 700;
    }

    &__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 6px;

      border-radius: 4px;
      background: var(--secondary-color-300);

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: var(--secondary-color-500);
    }
  }
}
