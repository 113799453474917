:import("@/assets/styles/components/info-tooltip.module.scss") {
  locl-info-tooltip-icon: locl-info-tooltip-icon;
}

.locl-header-region-currency-dropdown {
  display: flex;
  align-items: center;
  grid-gap: 12px;

  &__button {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
  }

  &__button-globe {
    width: 20px;
    height: 20px;
  }

  &__button-arrow {
    width: 12px;
    height: 12px;
  }

  &__button-info {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--white-color-500);
    opacity: 0.6;

    width: max-content;
  }

  &__dropdown {
    width: 230px;
    padding: 12px;
    border-radius: 4px;

    background: var(--white-color-500);

    &__body {
      display: flex;
      flex-direction: column;
      grid-gap: 12px;
    }

    &__select {
      :global {
        .css-turnqp-control { cursor: pointer; }
      }
    }
  }

  .locl-info-tooltip-icon {
    width: 20px !important;
    height: 20px !important;
    padding: 0 !important;
    opacity: 0.6;
  }
}
